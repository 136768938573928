import React, { useEffect, useState } from "react"
import Event from "../Event"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import moment from "moment"
import { capitalizeFirstLetter } from "../../commons/commons"

function Schedule(props) {
  const { title, scheduleList, types, classNameTypes, selectedDate } = props
  const [customTitle, setCuestomTitle] = useState()

  useEffect(() => {
    if (selectedDate)
      setCuestomTitle(() => {
        const newTitle = capitalizeFirstLetter(
          moment(selectedDate).format("dddd DD [de] MMMM [de] YYYY")
        )
        return newTitle
      })
  }, [selectedDate])

  const renderScheduleList = () => {
    return (
      Array.isArray(scheduleList) &&
      scheduleList.map(item => {
        const url = item.category? item.category.slug : item?.category_name?.split(" ").join("-").toLowerCase()
        return (
          <Event
            className="Event-short"
            showIcon
            path={item.category && `/servicios-y-actividades/${item.category.pk}/${url}/${item.pk}/${item.slug}`}
            subtitle={item.subtitle}
            title={item.title}
            colorBorder={item?.category_color}
            date={item?.description_dates_calendar}
            id={item?.pk}
          />
        )
      })
    )
  }

  const renderTypes = () => {
    return (
      types &&
      types.map(item => (
        <Link
          className="Schedule-type"
          to={`/servicios-y-actividades/${item.pk}/${item.slug}`}
        >
          <span
            className={`Schedule-type-color`}
            style={{ background: item?.color_hexadecimal }}
          ></span>
          <span className="Schedule-type-text">{item?.title}</span>
        </Link>
      ))
    )
  }

  return (
    <div className="Schedule">
      <h3 className="Schedule-title">{customTitle || title}</h3>
      <div className="Schedule-items">{renderScheduleList()}</div>
      <div className="Schedule-types">
        <div className={`Schedule-types-wrapper ${classNameTypes}`}>
          {renderTypes()}
        </div>
      </div>
    </div>
  )
}

Schedule.propTypes = {
  title: PropTypes.string,
  selectedDate: PropTypes.string,
  scheduleList: PropTypes.array.isRequired,
  types: PropTypes.array.isRequired,
}

export default Schedule
