import React, { useState } from "react"
import PropTypes from "prop-types"
import DatePicker, { registerLocale } from "react-datepicker"
import es from "date-fns/locale/es"
import moment from "moment"
registerLocale("es", es)

const Calendar = props => {
  const { className, onChange, dates } = props

  const [startDate, setStartDate] = useState(new Date())

  const momentFormat = date => {
    const mommentFormat = moment(date).format("YYYY-MM-DD")
    return mommentFormat
  }

  const handleChangeDate = dateSelect => {
    setStartDate(dateSelect)

    if (onChange && typeof onChange === "function") {
      onChange(momentFormat(dateSelect))
    }
  }

  const highlightWithRanges = dates?.dates?.map(d => {
    const [day, month, year] = d.split("/")
    return new Date(parseInt(year), parseInt(month - 1), parseInt(day))
  })

  return (
    <div className={`Calendar ${className}`}>
      <i className="fas fa-arrow-left a"></i>
      <DatePicker
        dateFormat="dd/MM/yyyy"
        selected={startDate}
        onChange={handleChangeDate}
        inline
        highlightDates={highlightWithRanges}
        locale="es"
      />
      <i className="fas fa-arrow-right"></i>
    </div>
  )
}

Calendar.propTypes = {
  date: PropTypes.instanceOf(Date),
  className: PropTypes.string,
  onChange: PropTypes.func,
}

export default Calendar
